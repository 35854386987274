<template>
  <div>
    <div>
      <button class="centerBtn" @click="addShow">添加角色</button>
    </div>
    <div class="mt20">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model.trim="searchObj.roleName"
            clearable
            placeholder="请输入角色名"
            class="centerInput"
          ></el-input>
        </el-form-item>

        <!-- 当表单只有一个文本框时，按下回车将会触发表单的提交事件 -->
        <!--  加一个隐藏的文本框解决回车触发表单的提交 -->
        <el-form-item style="margin-bottom: 0; display: none">
          <el-input></el-input>
        </el-form-item>

        <el-form-item>
          <button class="centerCzbtn" type="button" @click="search">
            搜索
          </button>
        </el-form-item>
      </el-form>
    </div>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="searchObj.page"
      :size="searchObj.size"
    />

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>确认删除角色</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="delRole">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 添加编辑抽屉 -->
    <el-drawer
      :title="isAdd ? '添加角色' : '编辑角色'"
      :visible.sync="addDia"
      destroy-on-close
      @closed="handleClosed"
    >
      <div class="drawer__content">
        <el-form
          ref="addForm"
          :model="addForm"
          label-width="110px"
          :rules="addFormRule"
        >
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model="addForm.roleName"></el-input>
          </el-form-item>
          <el-form-item label="设置功能权限" prop="menuIds">
            <TreeSelect
              v-if="treeData.length"
              :options="treeData"
              multiple
              v-model="addForm.menuIds"
              @getValue="getValue"
            ></TreeSelect>
          </el-form-item>
          <el-form-item label="园区访问权限" prop="parkIds" v-if="isShowPark">
            <el-checkbox-group v-model="addForm.parkIds">
              <el-checkbox
                :label="item.parkId"
                name="type"
                v-for="item in parkList"
                :key="item.parkId"
                >{{ item.parkName }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="drawer__footer">
        <el-button
          type="primary"
          @click="addRole"
          v-if="isAdd"
          :loading="btnLoading"
          >确定</el-button
        >
        <el-button
          type="primary"
          @click="updateRole"
          v-else
          :loading="btnLoading"
          >确定</el-button
        >
        <el-button @click="addDia = false">取 消</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from '../../components/Table.vue'
import {
  _FindAll,
  _FindAllRolePage,
  _AddRoleCenter,
  _UpdateRoleCenter,
  _DeleteRoleCenter,
  _CheckRoleName,
  _CheckUpdateRoleName,
  _UpdateRoleMenu,
  _AddRole,
  _UpdateRole,
  _GetAllPark
} from '@/api/systemManagement/roleManage'
import { _FindAllPage } from '@/api/selCar'
import qs from 'qs'
import TreeSelect from '@/components/treeSelect.vue'
// import TreeSelect2 from "@/components/treeSelect2.vue";
export default {
  components: {
    Table,
    TreeSelect
  },
  data() {
    // 添加角色校验是否唯一
    const checkRoleName = async (rule, value, callback) => {
      try {
        let isOnly = false
        if (this.isAdd) {
          let { success } = await _CheckRoleName({
            roleName: value,
            companyId: this.$store.state.companyId
          })
          isOnly = success
        } else {
          let { success } = await _CheckUpdateRoleName({
            roleName: value,
            roleId: this.addForm.roleId
          })
          isOnly = success
        }
        if (isOnly) {
          callback()
        } else {
          callback(new Error('该角色名已存在!'))
        }
      } catch (error) {
        callback(new Error('网络错误，校验唯一失败！'))
      }
    }
    return {
      tree: [],
      isShowPark: false,
      loading: false,
      delDia: false,
      jurisdiction: false,
      total: 0,
      menuIds: [],
      diaLoading: false,
      isAdd: false,
      formLabelWidth: '70px',
      addDia: false,
      btnLoading: false, // 编辑添加loading
      checkSt: false,
      parkList: [],
      addForm: {
        roleName: null, // 角色名
        companyId: this.$store.state.companyId,
        menuIds: [], // 角色权限
        parkIds: [], // 园区权限
        roleId: null // 角色ID
      },
      addFormRule: {
        roleName: [
          { required: true, message: '请输入角色名', trigger: 'blur' },
          { validator: checkRoleName, trigger: 'blur' }
        ],
        menuIds: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个功能权限',
            trigger: 'change'
          }
        ],
        parkIds: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个园区访问权限',
            trigger: 'change'
          }
        ]
      },
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      searchObj: {
        roleName: '',
        page: 1,
        size: 10,
        companyId: this.$store.state.companyId
      },
      form: {
        roleName: ''
      },
      treeData: [],
      rowData: {},
      addrules: {
        roleName: [
          {
            required: true,
            message: '请输入角色',
            trigger: 'blur'
          }
        ]
      },
      tableData: [],
      columns: [
        {
          label: '角色',
          prop: 'roleName'
        },
        {
          label: '创建时间',
          prop: 'createTime'
        },
        {
          label: '创建人',
          prop: 'createUserName',
          render: (h, params) => {
            if (params.row.createUserName) {
              return h('span', params.row.createUserName)
            } else {
              return h('span', '-')
            }
          }
        },
        {
          label: '操作',
          // fixed: "right",
          width: 250,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'operationA ',
                  on: {
                    click: () => {
                      this.isAdd = false
                      this.addDia = true
                      this.addForm.roleName = params.row.roleName
                      this.addForm.menuIds = params.row.menuIds
                      this.addForm.parkIds = params.row.parkIds
                        ? params.row.parkIds
                        : []
                      this.addForm.roleId = params.row.id
                    }
                  }
                },
                '编辑'
              ),
              h(
                'span',
                {
                  class: 'operationA ml20',
                  on: {
                    click: () => {
                      this.delDia = true
                      this.rowData = params.row
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ]
    }
  },
  mounted() {
    this.getTableData()
    this.getMenuAll()
    this.getAllPark()
  },
  methods: {
    // 重置
    reset() {
      this.searchObj.roleName = ''
      this.searchObj.page = 1
      this.searchObj.size = 10

      this.getTableData()
    },
    addShow() {
      this.form.roleName = ''
      ;(this.addDia = true), (this.isAdd = true)
    },
    // 搜索
    search() {
      this.searchObj.page = 1
      let searchPage = {
        limit: 10,
        page: 1
      }
      this.getTableData(searchPage)
    },
    //删除
    async delRole() {
      let { success, message, data } = await _DeleteRoleCenter({
        roleId: this.rowData.id
      })
      if (success) {
        this.$message.success('添加成功')
        this.delDia = false
        this.reset()
      } else {
        this.$message.error(message)
      }
    },
    // 获取列表
    async getTableData(pagination) {
      this.loading = true
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page
          this.searchObj.size = pagination.limit
        }
      }
      let { message, success, data } = await _FindAllRolePage(this.searchObj)
      if (success) {
        this.tableData = data.list
        this.total = data.total
      }
      this.loading = false
    },
    // 关闭抽屉
    handleClosed() {
      this.isShowPark = false
      this.addForm = {
        roleName: null, // 角色名
        companyId: this.$store.state.companyId,
        menuIds: [], // 角色权限
        parkIds: [], // 园区权限
        roleId: null // 角色ID
      }
    },
    //获取所有权限
    getMenuAll() {
      //  this.treeData=this.$store.state.routes
      _FindAllPage({ type: 1 }).then(res => {
        this.treeData = res.data
      })
    },
    // 获取全部园区
    async getAllPark() {
      let { data } = await _GetAllPark()
      this.parkList = data
    },
    getValue(value, title) {
      this.isShowPark = title.includes('园区运维中心')
    },
    // 添加角色
    addRole() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true
          try {
            let { success } = await _AddRole(this.addForm)
            if (success) {
              this.$message.success('添加成功')
              this.addDia = false
              this.getTableData()
            } else {
              this.$message.error('添加失败')
            }
            this.btnLoading = false
          } catch (error) {
            this.addDia = false
            this.btnLoading = false
          }
        }
      })
    },
    // 编辑角色
    updateRole() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true
          try {
            let { success } = await _UpdateRole(this.addForm)
            if (success) {
              this.$message.success('编辑成功')
              this.addDia = false
              this.getTableData()
            } else {
              this.$message.error('编辑失败')
            }
            this.btnLoading = false
          } catch (error) {
            this.addDia = false
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-drawer__body {
  display: flex;
  flex-direction: column;
  .drawer__content {
    flex: 1;
    .upload-demo {
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-upload__tip {
        margin-top: 10px;
        width: 80%;
        display: flex;
        justify-content: space-between;
        :last-child {
          color: #409eff;
          cursor: pointer;
        }
      }
    }
  }
  .drawer__footer {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
